<template>
  <div>
    <div class="numeric-block" v-if="showPave">
      <div class="screen"> {{ parseFloat($store.getters["nextore/getTapeValue"]).toFixed(2) }}</div>
      <div class="touch">
        <div class="numbers">
          <div class="number" v-on:click="tapeNumber('7')"><label>7</label></div>
          <div class="number" v-on:click="tapeNumber('8')"><label>8</label></div>
          <div class="number" v-on:click="tapeNumber('9')"><label>9</label></div>
          <div class="number" v-on:click="tapeNumber('4')"><label>4</label></div>
          <div class="number" v-on:click="tapeNumber('5')"><label>5</label></div>
          <div class="number" v-on:click="tapeNumber('6')"><label>6</label></div>
          <div class="number" v-on:click="tapeNumber('1')"><label>1</label></div>
          <div class="number" v-on:click="tapeNumber('2')"><label>2</label></div>
          <div class="number" v-on:click="tapeNumber('3')"><label>3</label></div>
        </div>
        <div class="operator">
          <div class="number" v-on:click="tapeNumber('0')"><label>0</label></div>
          <div class="number" v-on:click="tapeNumber('00')"><label>00</label></div>
          <div class="number" v-on:click="tapeNumber('C')"><label>C</label></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    showPave: {
      type: Boolean,
      default: false,
    },
    selectedPaymentMethod: {
      type: Object,
      default: () => {
      },
    }
  },
  created() {
  },
  methods: {
    tapeNumber(val) {
      let tapeValue = parseFloat('' + this.$store.getters['nextore/getTapeValue']).toFixed(2);
      if (val === '0') {
        tapeValue = parseFloat('' + (tapeValue * 10)).toFixed(2);
      } else if (val === '00') {
        tapeValue = parseFloat('' + (tapeValue * 100)).toFixed(2);
      } else if (val === 'C') {
        tapeValue = 0;
      } else {
        tapeValue = (tapeValue * 10) + (val / 100);
      }
      this.$store.dispatch('nextore/setTapeValue', parseFloat('' + tapeValue).toFixed(2));
    }
  }
};
</script>

<style scoped lang="scss">
.action-open {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #3B4C68;
  color: white;
  display: flex;
  flex-direction: row;
  z-index: 7;
  border-radius: 20px;
  padding: 10px;
  align-items: center;

  label {
    font-size: 17px;
    font-weight: 700;
    margin-left: 10px;
  }

  i {
    font-size: 30px;
    margin-top: -5px;
  }
}

.numeric-block {
  .close {
    position: absolute;
    right: -10px;
    background-color: #2e3a4c;
    border: 1px solid white;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    opacity: 1;

    i {
      margin: auto;
      color: white;
      font-size: 20px;
    }
  }

  z-index: 9;
  text-align: right;
  position: relative;
  right: 0;
  background-color: #B6C3CF;
  width: 100%;
  max-width: 500px;
  max-height: 450px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .screen {
    min-width: 100%;
    max-width: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 10px;
    min-height: 50px;
    max-height: 50px;
    font-size: 25px;
    font-weight: 800;
    text-align: right;
    padding: 10px;
  }

  .touch {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;

    .numbers {
      min-width: 75%;
      max-width: 75%;
      display: inline-block;

      .number {
        display: inline-flex;
        align-items: center;
        min-width: calc((100% - 30px) / 3);
        max-width: calc((100% - 30px) / 3);
        min-height: 50px;
        max-height: 50px;
        background-color: rgba(255, 255, 255, 0.7);
        border-radius: 10px;
        font-size: 30px;
        font-weight: 900;
        text-align: center;
        margin-right: 10px;
        margin-top: 10px;
        cursor: pointer;

        label {
          margin: auto;
        }
      }

      .number:hover {
        background-color: #2e3a4c;
        color: white;
      }
    }

    .operator {
      min-width: 25%;
      max-width: 25%;
      display: flex;
      flex-direction: column;

      .number {
        display: inline-flex;
        align-items: center;
        min-width: 100%;
        max-width: 100%;
        min-height: 50px;
        max-height: 50px;
        background-color: #3B4C68;
        color: white;
        border-radius: 10px;
        font-size: 30px;
        font-weight: 900;
        text-align: center;
        margin-right: 10px;
        margin-top: 10px;
        cursor: pointer;

        label {
          margin: auto;
        }
      }

      .number:hover {
        background-color: #2e3a4c;
        color: white;
      }
    }
  }

}
</style>
